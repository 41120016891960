import React from "react"
import ReactModal from 'react-modal';
import { AiOutlineClose } from "react-icons/all"

const StrataDocumentsModal = ({ open, openModal, documentUrl }) => {

  function closeModal(event) {
    event.stopPropagation()
    openModal("")
  }

  return (
    <div>
      <ReactModal
        className="custom-modal custom-sm-flex-order"
        isOpen={open}
        preventScroll={false}
        style={{
          overlay: {
            position: 'fixed',
            overflow: 'hidden',
            backgroundColor: 'rgba(255,255,255,0.55)',
          }
        }}
        onAfterOpen={() => document.body.style.overflow = 'hidden'}
        onAfterClose={() => document.body.style.overflow = 'unset'}
        contentLabel="Strata Form"
      >
        <button
          onClick={closeModal}
          className="btn btn-primary pull-right modal-button">
          <AiOutlineClose/>
        </button>
        <span></span>
        <iframe className="iframe" id="strata-document" src={documentUrl} width='100%' height='93%' title='strata-document'></iframe>
      </ReactModal>
    </div>
  )
}

export default StrataDocumentsModal
